<template>
  <food-plan ref="planComponent" :client="client" :plan="foodPlan" :base-used="baseUsed" :header-fixed="true" :has-back="true" />
</template>

<script>
import Utils from '@/services/Utils'

export default {
  components: {
    FoodPlan: () => import('@/components/dashboard/foodPlan/FoodPlan'),
  },
  beforeRouteLeave: function (to, from, next) {
    if (this.$refs.planComponent && this.$refs.planComponent.destroyValidation) {
      this.$refs.planComponent.destroyValidation(next)
    }
  },
  data() {
    const data = Utils.getStorage('foodPlan')
    const client = data ? data.client : {}
    const foodPlan = data ? data.data : {}
    const baseUsed = data ? data.baseUsed : false
    return {
      foodPlan: foodPlan,
      client: client,
      baseUsed: baseUsed,
    }
  },
}
</script>
